<template>
  <v-container
    class="person-table-filter"
    fluid
  >
    <v-row>
      <v-col>
        <v-btn
          :color="viewAllInputs ? 'primary' : 'midgray'"
          :ripple="false"
          text
          @click="viewAllInputs = !viewAllInputs"
        >
          <span>All Filters</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="personName"
          label="Name"
          :options="humans"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="email"
          label="Email"
          :options="emailFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="isConfigValid"
          label="Is Config Valid"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="hasAccessErrors"
          label="Has Access Errors"
          :options="hasAccessErrorsFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="assoc_email"
          label="Created/Updated By"
          :options="internals"
          value="personId"
          type="textual"
        />
      </v-col>
    </v-row>

    <date-input
      class="px-4"
      filter-key="created_dt"
      label="Created On"
    />

    <date-input
      class="px-4"
      filter-key="updated_dt"
      label="Last Updated"
    />

    <date-input
      class="px-4"
      filter-key="access.expires"
      label="Expires"
    />

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="isPerson"
          label="Person Exists"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="isPersonAuthorized"
          label="Person Auth"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="isDeleted"
          label="Person Deleted"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="isActive"
          label="Person Active"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="personRole"
          label="Person Role"
          :options="roleFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="isUser"
          label="User Exists"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="isUserAuthorized"
          label="User Auth"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="access.active"
          label="User Active"
          :options="booleanOptions"
          type="boolean"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="access.level"
          label="User Level"
          :options="levelFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row wrap>
      <v-col class="pa-0">
        <text-input
          filter-key="access.role"
          label="User Role"
          :options="roleFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="billingCodes"
          label="Billing Codes"
          :options="billingCodesFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="networkCodes"
          label="Network Codes"
          :options="networkCodesFilterValues"
          value="networkCode"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="orgIds"
          label="Org IDs"
          :options="orgIdsFilterValues"
          value="orgId"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="orgNames"
          label="Org Names"
          :options="orgNamesFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="teamCodes"
          label="Team Codes"
          :options="teamCodesFilterValues"
          value="teamCode"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="teamIds"
          label="Team IDs"
          :options="teamIdsFilterValues"
          value="teamId"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="teamNames"
          label="Team Names"
          :options="teamNamesFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="viewAllInputs"
      wrap
    >
      <v-col class="pa-0">
        <text-input
          filter-key="managerPersonId"
          label="Manager ID"
          :options="internals"
          value="personId"
          type="strict"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pa-0">
        <text-input
          filter-key="managerPersonName"
          label="Manager Name"
          :options="internals"
          type="textual"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import DateInput from 'src/tools/components/filter/DateInput'
import TextInput from 'src/tools/components/filter/TextInput'

const { mapGetters: mapInternalGetters } = createNamespacedHelpers('internal')
const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'PersonFilterForm',
  components: {
    DateInput,
    TextInput,
  },
  data() {
    return {
      viewAllInputs: false,
    }
  },
  computed: {
    ...mapInternalGetters([
      'humans',
      'internals',
    ]),
    ...mapToolsGetters([
      'billingCodesFilterValues',
      'emailFilterValues',
      'hasAccessErrorsFilterValues',
      'levelFilterValues',
      'networkCodesFilterValues',
      'orgIdsFilterValues',
      'orgNamesFilterValues',
      'teamCodesFilterValues',
      'teamIdsFilterValues',
      'teamNamesFilterValues',
      'roleFilterValues',
      'visibleColumns',
    ]),
    ...mapToolsState({
      booleanOptions: (state) => state.booleanOptions,
      filterData: (state) => state.filterData,
      reportName: (state) => state.reportName,
    }),
  },
  methods: {
    isVisibleColumn(columnKey) {
      return this.visibleColumns.find((visibleColumn) => visibleColumn.key === columnKey)
    },
  },
}
</script>
