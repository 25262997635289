<template>
  <v-container class="pa-0">
    <date-input
      class="px-4"
      filter-key="date"
      label="Date"
    />

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="topLevelAdUnitCode"
          label="Top-Level Ad Unit Id"
          :options="topLevelAdUnitCodeFilterValues"
          type="strict"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="topLevelAdUnitName"
          label="Top-Level Ad Unit Name"
          :options="topLevelAdUnitNameFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="deviceCategoryName"
          label="Device Category"
          :options="deviceCategoryNameFilterValues"
          type="textual"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="requestedAdSizes"
          label="Requested Sizes"
          :options="requestedAdSizesFilterValues"
          type="strict"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="unfilledImpressions"
          label="Unfilled Impressions"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="totalImpressions"
          label="Total Impressions"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="allImpressions"
          label="All Impressions"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="unfilledPercentage"
          label="Unfilled Percentage"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>

    <v-row
      class="px-4"
    >
      <v-col class="pa-0">
        <text-input
          filter-key="revenue"
          label="Revenue"
          :options="[]"
          type="numerical"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import DateInput from 'src/tools/components/filter/DateInput'
import TextInput from 'src/tools/components/filter/TextInput'

const { mapGetters, mapState } = createNamespacedHelpers('tools')
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'HealthNetworkFilterForm',
  components: {
    DateInput,
    TextInput,
  },
  computed: {
    ...mapAuthGetters([
      'isInternalAdminUser',
    ]),
    ...mapGetters([
      'reportKey',

      'currentGroupByProperties',
      'currentMergeByProperties',

      'deviceCategoryNameFilterValues',
      'requestedAdSizesFilterValues',
      'topLevelAdUnitCodeFilterValues',
      'topLevelAdUnitNameFilterValues',
    ]),
    ...mapState({
      booleanOptions: (state) => state.booleanOptions,
      filterData: (state) => state.filterData,

      groupingOptions: (state) => state.groupingOptions,
      groupByProperties: (state) => state.groupByProperties,
      grouping: (state) => state.grouping,

      mergingOptions: (state) => state.mergingOptions,
      mergeByProperties: (state) => state.mergeByProperties,
      merging: (state) => state.merging,

      reportName: (state) => state.reportName,
      subId: (state) => state.subId,
    }),
    isHealthAdx() {
      return this.reportKey === 'health/adx'
    },
    isHealthNetwork() {
      return this.reportKey === 'health/network'
    },
    isHealthReport() {
      return this.reportName === 'health'
    },
    isYieldReport() {
      return this.reportName === 'yield'
    },
    groupedBy: {
      get() {
        const { currentGroupByProperties } = this

        return currentGroupByProperties || []
      },
      set(groupByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/isCurrentlyGrouping', true)
        this.$store.commit('tools/grouping', { groupByProperties, reportKey })

        return groupByProperties
      },
    },
    mergedBy: {
      get() {
        const { currentMergeByProperties } = this

        return currentMergeByProperties || []
      },
      set(mergeByProperties) {
        const { reportKey } = this

        this.$store.commit('tools/isCurrentlyMerging', true)
        this.$store.commit('tools/merging', { mergeByProperties, reportKey })

        return mergeByProperties
      },
    },
  },
  watch: {
    reportKey(newValue, oldValue) {
      return newValue !== oldValue
    },
  },
}
</script>
